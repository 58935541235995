import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const FooterLinkItemSocial = ({data}) => {
    return (
        <div className="footer-widget">
            <h4 className="footer-widget-title">{data.title}</h4>
            <div className="footer-widget-content">
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 g-0 mesonry-list">
                    {data.list.map((single,key) =>(
                        <div key={key}>
                            <Link to={single.url}>{single.text} {single?.badge && <span className="ft-badge">{single.badge}</span>} </Link>
                            <a href={single.url}>
                            <img src={`${process.env.PUBLIC_URL}/${single.image}`} style={{width:"30px", borderRadius: 10}}></img>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

FooterLinkItemSocial.propTypes = {
    data: PropTypes.object
};

export default FooterLinkItemSocial;
