import React, { useState, useEffect } from "react";
import YoutubeBackground from 'react-youtube-background';
import { Link as ScrollLink } from "react-scroll";
//import NewsletterForm from '../../components/Newsletter/NewsletterForm';
//import FungibleStrategy from '../../assets/images/brand/fungible-strategy.png';
const IntroTwo = () => {

    const [isDesktop, setDesktop] = useState(window.innerWidth > 799);

    const updateMedia = () => {
      setDesktop(window.innerWidth > 799);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <>
        { isDesktop? (

        <YoutubeBackground
        videoId="d5AdN0IEnzc"
        overlay="rgba(0,0,0,.4)"
        className="intro-section section bg-video"
    >       <div id="particles-js">
                <div className="container" style={{ textAlign:"center" }}>
                    <div className="row row-cols-lg-1 row-cols-1">
                        <div className="col align-self-center">
                            <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                <h2 className="title">We offer strategic support for Blockchain projects:</h2>
                                <h2 className="title-3">NFTs, DAOs, DeFi</h2>
                                 {/*<img  src={FungibleStrategy} alt='fungible strategy' style={{ width: '80%' }}/>*/}
                                <ScrollLink
                                    className="btn btn-primary"
                                    to="team"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    <b>Meet the core team</b>
                                </ScrollLink>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            {/*
                            <div className="newsletter-content">
                                <NewsletterForm mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </div>
            </ YoutubeBackground  >
            
        ) :

        (

            <YoutubeBackground
        videoId="9FdPKuvV-48"
        overlay="rgba(0,0,0,.4)"
        className="intro-section section bg-video"
    >
                <div className="container" style={{ textAlign:"center" }}>
                    <div className="row row-cols-lg-1 row-cols-1">
                        <div className="col align-self-center">
                            <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                                <h2 className="title">We offer strategic support for Blockchain projects:</h2>
                                <h2 className="title-3">NFTs, DAOs, DeFi</h2>
                                 {/*<img  src={FungibleStrategy} alt='fungible strategy' style={{ width: '80%' }}/>*/}
                                <ScrollLink
                                    className="btn btn-primary"
                                    to="team"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    <b>Meet the core team</b>
                                </ScrollLink>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            {/*
                            <div className="newsletter-content">
                                <NewsletterForm mailchimpUrl="//devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" />
                            </div>
                            */}
                        </div>
                    </div>
                </div>
            </ YoutubeBackground  >
          )}

        </>
    )
}


export default IntroTwo;
