import React from "react";

const TermsService = () => {
    return(
        <>
            <div className="container section-padding" >
                <div className="row align-items-center section section-padding">
                <h1>Terms and Conditions of Use</h1>

                    <p>The purpose of these general terms and conditions of use (hereinafter referred to as the &quot;TCU&quot;) is to provide a legal framework for the terms and conditions under which <b>Fungible Strategy</b> makes the site and its services available and to define the conditions of access to and use of the services by the &quot;User&quot;.
                        These TCU are available on the site under the heading &quot;TCU&quot;.
                    </p>

                <h2>ARTICLE 1: Legal notice</h2>

                    <p>The Service Provider is the Individual Company (EI), <b>FUNGIBLE STRATEGY</b> represented by <b>ENZO SOYER.</b>
                        <br></br>
                        <b>Address:</b> 6 RUE D ARMAILLE 75017 PARIS.
                        <br></br>
                        <b>SIRET:</b> 91761782100017.
                        <br></br>
                        <b>RCS number:</b> 917617821 .
                        <br></br>
                        <b>VAT number:</b> FR 71917617821.
                        <br></br>
                        <b>E-mail address:</b> <b>hello@fungiblestrategy.io</b>
                        <br></br>
                        <b>Telephone number:</b> +33 6 50 14 26 72.
                    </p>
                    <p>
                        The edition and the direction of the publication of the site <b>http://www.fungiblestrategy.io/</b> is assured by Jesús Reinaldo Molina González, domiciled Urquiza 1059, Paraná Entre Ríos.
                        <br></br>
                        <b>E-mail address:</b> reymolinagonz@gmail.com
                        <br></br>
                        <b>Telephone number:</b> +54 343 440 3573.
                    </p>
                    <p>
                    The host of the site <a><b>http://www.fungiblestrategy.io/</b></a> is the company CLOUDFLARE FRANCE SAS, whose headquarters is located at 6 PL DE LA MADELEINE 75008 PARIS, with the telephone number: 0757905273.
                    </p>

                <h2>ARTICLE 2: Access to the site</h2>

                    <p>
                        The site <a><b>http://www.fungiblestrategy.io/</b></a> allows the User to see the range of services provided by the company.
                    </p>
                    <p>
                        These are the following services:
                            <br></br>
                            <b>Assistance in market analysis and implementation of marketing strategy.</b>
                            <br></br> 
                            <b>Assistance in the development of strategic partnerships.</b>
                            <br></br> 
                            <b>Assistance in the organization of project management.</b>
                            <br></br> 
                            <b>Strategic assistance in raising funds from Venture Capitalists (VCs) and Blockchain Foundations.</b>
                    </p>
                    <p>
                        However, this site is only a showcase. Thus, it is only possible to access these paid services after direct contact with Fungible Strategy and establishment of a quote. No services can be bought directly on the website. 
                    </p>
                    <p>
                        The services are aimed towards companies for a B2B relationship with Fungible Strategy. The appearance of the General Conditions of Sale on the site is thus optional but accessible by request from the Client or Prospect company (see Article 1 for communication channels).
                    </p>
                    <p>
                        The site is accessible free of charge at any location to any User with Internet access. All costs incurred by the User to access the service (computer hardware, software, Internet connection, etc.) are at the User&apos;s expense.
                    </p>
                
                <h2>ARTICLE 3: Data collection</h2>

                    <p>
                        The site is exempted from declaration to the National Commission for Data Processing and Liberties (CNIL) insofar as it does not collect any data concerning the Users.
                    </p>
                    <p>
                        The embedded Calendly Calendar collects data if the User interacts with the widget and accepts the cookies. However, <b>Fungible Strategy</b> does not manage any of this data. 
                    </p>
                    <p>
                        For that matter, users are free to refuse the collection of data by Calendly when the cookie popup appears for booking a meeting.
                    </p>
                    <p>
                        More details available on <a href="https://help.calendly.com/hc/en-us/articles/4418236309399"><b>Calendly’s website</b></a> about their cookie compliance with privacy regulations worldwide (GDPR, CCPA, etc.) for interacting with their widget.
                    </p>
                    <p>
                        Interaction with the Calendly widget to book a meeting is optional and users can make an appointment through the communication channels mentioned in article 1 if they prefer. 
                    </p>

                <h2>ARTICLE 4: Intellectual property</h2>

                    <p>
                        The brands, logos, signs as well as all the contents of the site (texts, images, sound...) are protected by the Code of the intellectual property and more particularly by the copyright.
                    </p>
                    <p>
                        The User must request prior authorization from the site for any reproduction, publication or copy of the various contents. The User undertakes to use the contents of the site in a strictly private context; any use for commercial or advertising purposes is strictly forbidden.
                    </p>
                    <p>
                        Any total or partial representation of this site by any process whatsoever, without the express permission of the operator of the website would constitute an infringement punishable by Article L 335-2 and following of the Code of Intellectual Property.
                    </p>
                    <p>
                        It is recalled in accordance with Article L122-5 of the Code of Intellectual Property that the User who reproduces, copies, or publishes the protected content must cite the author and its source.
                    </p> 
                
                <h2>ARTICLE 5: Responsibility</h2>
    
                <p>
                    The sources of information disseminated on the site <a><b>http://www.fungiblestrategy.io/</b></a> are deemed reliable but the site does not guarantee that it is free of defects, errors or omissions.
                </p>
                <p>
                    The information communicated is presented as an indication and general without contractual value. Despite regular updates, the site http://www.fungiblestrategy.io/ cannot be held responsible for changes in administrative and legal provisions occurring after publication. Similarly, the site cannot be held responsible for the use and interpretation of the information contained in this site.
                </p>
                <p>
                    The site <a><b>http://www.fungiblestrategy.io/</b></a> cannot be held responsible for any viruses that may infect the computer or any computer equipment of the Internet user, following use, access or downloading from this site.
                </p>
                <p>
                    The responsibility of the site cannot be engaged in case of force majeure or unforeseeable and insurmountable fact of a third party.
                </p>
                
                
                <h2>ARTICLE 6: Hypertext links</h2>
                <p>
                Hypertext links can be present on the site. The User is informed that by clicking on these links, he will leave the site <a><b>http://www.fungiblestrategy.io/.</b></a> The latter has no control over the web pages on which these links lead and cannot, in any case, be responsible for their content.
                </p>
                <span style={{marginBottom: 10}}></span>
                </div>
            </div>
        </>
    )
};

export default TermsService;