import PropTypes from "prop-types";
//import React from 'react';
import ReactVivus from 'react-vivus';
//import {Link} from "react-router-dom";
import React,{useState} from "react";
//import { Link } from "react-router-dom";

const IconBox = ({ data, classOption }) => {
const [show,setShow]=useState(false)
const handleClick = () => setShow((show) => !show);

    return (
        <div className={`icon-box text-center ${classOption}`}>
            <div className="icon">
                <ReactVivus
                    id={`servicesvg-${data.id}`}
                    option={{
                        file: data.icon,
                        animTimingFunction: 'EASE',
                            type: 'oneByOne',
                            delay: 80
                    }}
                />
            </div>
            <div className="content">
                <h3 className="title">{data.title}</h3>
                <h3 className="title">{data.title1}</h3>
                <div className="desc">
                    <p>{data.desc}</p>
                </div>
                <div className="link">
                    <p> 
                            {
                            show?<h5 className="dropdown">{data.Sub1}</h5>:null
                            }
                            {
                            show?<h6 className="icon-box-information description dropdown">{data.desc1}</h6>:null
                            }
                        <br></br>
                            {
                            show?<h5 className="dropdown">{data.Sub2}</h5>:null
                            }
                            {
                            show?<h6 className="icon-box-information description dropdown">{data.desc2}</h6>:null
                            }
                        <br></br>
                            {
                            show?<h5 className="dropdown">{data.Sub3}</h5>:null
                            }
                            {
                            show?<h6 className="icon-box-information description dropdown">{data.desc3}</h6>:null
                            }
                        
                       {
                        show?
                        <button className="learn-more" onClick={()=>handleClick(true)}> {data.close}
                        </button>:
                        <button className="learn-more" onClick={()=>handleClick(true)}> {data.pageLink}
                        </button>
                        }
                        
                    </p>
                </div>
                {/*<Link to={data.link} className="link">{data.pageLink}</Link>*/}
            </div>
        </div>
    )
}

IconBox.propTypes = {
    data: PropTypes.object,
    classOption: PropTypes.string
};

IconBox.defaultProps = {
    classOption: "icon-box text-center",
};

export default IconBox;
