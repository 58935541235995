import React from 'react';
import Accordion, { AccordionItem, AccordionTitle, AccordionContent } from "../Accordion";

const AccordionWrap = () => {
    return (
        <div className="agency-accordion max-mb-n30">
            <Accordion>
                <AccordionItem id="one">
                    <AccordionTitle id="one">- How does fundraising work?</AccordionTitle>
                    <AccordionContent id="one">We help you review and reformulate your business model and pitch deck in the most appealing way for investors. Then, we accompany you through the application process and the presentation to investors.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="two">
                    <AccordionTitle id="two">- What is the process of working with Fungible Strategy?</AccordionTitle>
                    <AccordionContent id="two">It&apos;s a simple process where we hold regular meetings to advise you on blockchain topics that are relevant to your business. We can also work with you in an integrated way, as a member of your team, until the delivery of your Web 3 solution.</AccordionContent>
                </AccordionItem>
                <AccordionItem id="three">
                    <AccordionTitle id="three">- Which payment methods are accepted?</AccordionTitle>
                    <AccordionContent id="three">We accept traditional payment methods, and crypto payments</AccordionContent>
                </AccordionItem>
                <AccordionItem id="four">
                    <AccordionTitle id="four">- Why did Fungible Strategy spin out of the Tenk DAO incubator?</AccordionTitle>
                    <AccordionContent id="four">We realized that the potential to help Web 2 projects integrate with Web 3 goes far beyond Near Protocol. Yet, Tenk DAO is mainly focused on the latter blockchain. So it seemed logical to separate the activities to facilitate the evolution in different environments.</AccordionContent>
                </AccordionItem>
            </Accordion>
        </div>
    )
}

export default AccordionWrap;
