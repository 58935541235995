import React from 'react';
import SectionTitle from '../../components/SectionTitles/SectionTitle';
import { Link as ScrollLink } from "react-scroll";

const CallToActionTwo = () => {
    return (
        <div className="cta-section section section-padding-250" id="ecosystem">
            <div className="container text-center icon-up-down-animation" >
                <SectionTitle
                    headingOption="fz-34"
                    title="Let's talk about your project and see how we can work together."
                    subTitle="Our team has experience and expertise in the blockchain environment. We can therefore tailor your project to the blockchain network you want to launch it on."
                />
                <ScrollLink
                    className="btn btn-primary"
                    to="contact-us"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    <b>Connect to Multichain</b>
                </ScrollLink>

                <div className="shape shape-1">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Aurora-icon.png"} alt="Aurora" />
                    </span>
                </div>
                <div className="shape shape-2">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Avalanche-icon.png"} alt="Avalanche" />
                    </span>
                </div>
                <div className="shape shape-3">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Cosmos-icon.png"} alt="Cosmos" />
                    </span>
                </div>
                <div className="shape shape-4">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/ETH-icon.png"} alt="Ethereum" />
                    </span>
                </div>
                <div className="shape shape-5">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Near-icon.png"} alt="Near Protocol" />
                    </span>
                </div>
                <div className="shape shape-6">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Flow-icon.png"} alt="Flow" />
                    </span>
                </div>
                <div className="shape shape-7">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Polygon-icon.png"} alt="Polygon" />
                    </span>
                </div>
                <div className="shape shape-8">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Solana-icon.png"} alt="Solana" />
                    </span>
                </div>
                <div className="shape shape-9">
                    <span>
                        <img src={process.env.PUBLIC_URL + "images/icon-animation/Descentralized-icon.png"} alt="Web Decentralize" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default CallToActionTwo;
