import React from 'react';
import {Link} from "react-router-dom";
import FooterDataSocial from '../../data/Footer/footerItemsocial.json';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';
import FooterLinkItemSocial from '../../components/Footer/FooterLinkItemSocial.jsx';

const Footer = () => {
    return (
        <div className="footer-section section footer-bg-color">
            <div className="container">
                <div className="row mb-lg-14 mb-md-10 mb-6">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                            <div className="footer-widget-content">

                                <div>
                                    <p style={{margin:"auto"}}>
                                        <img width="70%" src="../images/logo/fungible-footer-logo.png"></img>
                                    </p>    
                                </div>

                                <div className="content">

                                    <br></br>
                                    <p><Link to={process.env.PUBLIC_URL + "/"}>+33 6 50 14 26 72</Link></p>
                                    <p><Link to={process.env.PUBLIC_URL + "/"}>hello@fungiblestrategy.io</Link> </p>
                                </div>
                                {/*
                                    <div className="footer-social-inline">
                                        <a href="https://twitter.com/Fungible_strat" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square"></i></a>
                                    </div>
                                */}
                            </div>
                        </div>
                    </div>

                    {FooterData && FooterData.map((single, key) => {
                            return(
                                <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                    <FooterLinkItem data={single} key={key} />
                                </div>
                            ); 
                    })}
                    {FooterDataSocial && FooterDataSocial.map((single, key) => {
                            return(
                                
                                <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                    <FooterLinkItemSocial data={single} key={key} />
                                </div>
                            ); 
                    })}

                </div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">&copy; 2022-2023 <strong>Fungible Strategy</strong> Made with <i className="fas fa-heart text-danger"></i> by <a href="https://inursites.io" target="_blank" rel="noopener noreferrer"><strong>Inursites.io</strong></a></p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer
