import React from 'react'
import {NavLink} from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";

const NavBar = () => {
    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Homepage</span></NavLink>
                </li>
                <li>
                    <ScrollLink
                        className="menu-text"
                        to="ecosystem"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Ecosystem
                    </ScrollLink>
                </li>
                <li>
                    <ScrollLink
                        className="menu-text"
                        to="success-cases"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Portfolio
                    </ScrollLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/blog"}><span className="menu-text">Blog</span></NavLink>
                </li>
                <li>
                    <ScrollLink
                        className="menu-text"
                        to="contact-us"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Contact us
                    </ScrollLink>
                </li>
            </ul>
        </nav>
    )
}

export default NavBar
