import React from 'react';
import {NavLink} from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
/* import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";
*/
const MobileNavMenu = () => {
    /* const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    }; */
    return (
        <nav className="site-mobile-menu">
            <ul>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/"}><span className="menu-text">Homepage</span></NavLink>
                </li>
                <li>
                    <ScrollLink
                        className="menu-text"
                        to="ecosystem"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Ecosystem
                    </ScrollLink>
                </li>
                <li>
                    <ScrollLink
                        className="menu-text"
                        to="success-cases"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Success Cases
                    </ScrollLink>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/blog"}><span className="menu-text">Blog</span></NavLink>
                </li>
                <li>
                <ScrollLink
                        className="menu-text"
                        to="contact-us"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >
                        Contact us
                    </ScrollLink>
                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;
