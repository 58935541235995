import PropTypes from "prop-types";
import React from 'react';

const SingleTeam = ({ data }) => {
    return (
        <div className="team">
            <div className="thumb">
                <img src={process.env.PUBLIC_URL + data.image} alt="team Images" style={{borderRadius: "10px", width: "70%", margin: "auto", display: "block"}}/>
                <div className="overlay"></div>
                <ul className="social-icon text-center">
                    <li className="linkedin"><a target="_blank" rel="noopener noreferrer" href={data.linkedin} className="link"><i className="fab fa-linkedin"></i></a></li>
                    <li className="twitter"><a target="_blank" rel="noopener noreferrer" href={data.twitter} className="link" aria-label="Twitter"><i className="fab fa-twitter"></i></a></li>
                    <li className="instagram"><a target="_blank" rel="noopener noreferrer" href={data.malt} className="link" aria-label="Instagram">M</a></li>
                </ul>
            </div>
            <div className="team-info text-center">
                <div className="info">
                    <h5>{data.name}</h5>
                    <span>{data.designation}</span>
                </div>
            </div>
        </div>
    )
}

SingleTeam.propTypes = {
    data: PropTypes.object
};

export default SingleTeam;
