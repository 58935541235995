import PropTypes from "prop-types";
import React from 'react';


const Brand = ({ data }) => {
    return (
       
                <div className="container">
                    <div className="brand">
                        <a href={data.pageLink} target="_blank" rel="noopener noreferrer">
                            <img src={process.env.PUBLIC_URL + data.image} alt="logo image" style={{width: "250px"}}/>
                        </a>
                    </div>
                </div>
        

    )
}

Brand.propTypes = {
    data: PropTypes.object
}



export default Brand;
