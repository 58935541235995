import {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import AOS from "aos";
import NavScrollTop from './components/NavScrollTop';
import Home from './pages/Home';
import BlogGrid from './pages/BlogGrid';
import BlogDetails from './pages/BlogDetails';
import BlogCategories from './pages/BlogCategories';
import BlogTag from './pages/BlogTag';
import Contact from './pages/Contact';

// CSS File Here
import "aos/dist/aos.css";
import 'react-modal-video/scss/modal-video.scss';
import './assets/scss/style.scss';
import TermsOfService from "./pages/TermsOfService";


function App() {
  useEffect(() => {
    AOS.init({
        offset: 80,
        duration: 1000,
        once: true,
        easing: 'ease',
    });
    AOS.refresh();
    
  }, [])
  return (
      <Router>
        <NavScrollTop>
            <Switch>
              <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home}/>
              <Route path={`${process.env.PUBLIC_URL + "/terms-of-service"}`} exact component={TermsOfService}/>
              <Route path={`${process.env.PUBLIC_URL + "/blog"}`} component ={BlogGrid} />
              <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component ={BlogTag} />
              <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`}component ={BlogCategories} />
              <Route path={`${process.env.PUBLIC_URL + "/blog-details/:url"}`}component ={BlogDetails} />
              <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component ={Contact} />
              {/* <Route component ={NotFound} /> */}
            </Switch>
          </NavScrollTop>
      </Router>
  );
}

export default App;
