import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import IntroTwo from '../container/IntroSlider/IntroTwo';
import CallToActionTwo from '../container/CallToAction/CallToActionTwo';
import ServiceIconBox from '../container/service/ServiceIconBox';
import BrandContainer from '../container/Brand/BrandContainer';
//import ServicePrice from '../container/service/ServicePrice';
import Team from '../container/Team/Team';
import Portfolio from '../container/Portfolio/Portfolio';
import TestimonialContainer from '../container/Testimonial/TestimonialContainer';
import Newsletter from '../container/Newsletter/Newsletter';
// import HomeBlog from '../container/BlogGrid/HomeBlog';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import FAQ from '../container/Faq/Faq.js';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';





const Home = () => {
    return (
        <React.Fragment>
            <SEO title="Fungible Strategy || Home" />
            <Header />
            <IntroTwo />
            {/*<HomeAboutTwo />*/}
            <CallToActionTwo/>
            {/*<Funfact />*/}
            <ServiceIconBox classOption="bg-color-1" />
            <BrandContainer classOption="section-padding" />
            {/*<ServicePrice classOption="bg-color-1" />
            /*<HomeSkillWithVideo />*/}
            <Team />
            <Portfolio />
            <TestimonialContainer />
            
            <Newsletter />
            {/*<HomeBlog SectionBgColor="bg-primary-blue" />*/}
            {/*<ContactInformation />*/}
            <ContactInformationThree></ContactInformationThree>
            <FAQ />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}


export default Home;



