import React from 'react';
import SEO from '../components/SEO';
import HeaderBlog from "../partials/header/HeaderBlog";
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import TermsService from '../container/TermsOfService/TermsService';
const TermsOfService = () => {
    
    return(
        <React.Fragment>
            <SEO title="Fungible Strategy || Terms of Service" />
            <HeaderBlog />
            <TermsService />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )

};

export default TermsOfService;