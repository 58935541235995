import React from 'react';
import SEO from '../components/SEO';
import HeaderBlog from "../partials/header/HeaderBlog";
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';

const Contact = () => {
    return (
        <React.Fragment>
            <SEO title="Fungible Strategy || Contact" />
            <HeaderBlog />
            <ContactInformationThree />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Contact;

